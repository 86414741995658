<i18n>
{
  "de": {
    "pageTitle": "Liegenschaften hinzufügen",
    "returnButton": "Zurück"
  }
}
</i18n>

<template>
  <main-layout :ready="portfolioReady" :portfolio="portfolio">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <button-wrapper>
        <div>
          <router-link class="button" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id }}">{{ $t('returnButton') }}</router-link>
        </div>
      </button-wrapper>
      <br /><br />
      <add-buildings-to-portfolio :portfolio="portfolio" />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

import AddBuildingsToPortfolio from '@/components/admin-portfolios/AddBuildingsToPortfolio'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  components: {
    MainLayout,
    AddBuildingsToPortfolio
  }
}
</script>
